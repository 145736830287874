import {http, param} from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const emailServices = {
    findEmails() {
        return http.get(`${API_ROOT}/provider/emails`).then(resp => {
            return resp
        })
    },
    getEmail(type) {
        return http.get(`${API_ROOT}/provider/emails/${type}`).then(resp => {
            return resp
        })
    },
    async getEREmail(type) {
        return http.get(`${API_ROOT}/erresponse/${type}`).then(resp => {
            return resp
        })
    },
    updateEmail(payload) {
        return http.put(`${API_ROOT}/provider/emails/${payload.email_type}`, payload).then(resp => {
            return resp
        })
    },
}

export default emailServices
