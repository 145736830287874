import { http } from '../../utils/http'
import conf from '../../config'

export const API_ROOT = `${conf.API_URL}`

const providerAppServices = {
    getProfile() {
        return http.get(`${API_ROOT}/provider/profile`).then(resp => {
            return resp
        })
    },
    getProviderSelfRegistrationRole(organizationid)
    {
        return http.get(`${API_ROOT}/account/${organizationid}`).then(resp =>
        {
            return resp
        })
    } ,
    updateInfo(payload) {
        return http.put(`${API_ROOT}/provider/profile/info`, payload).then(resp => {
            return resp
        })
    },
    updateSecuritySetting(payload) {
        return http.put(`${API_ROOT}/provider/profile/security-setting`, payload).then(resp => {
            return resp
        })
    },
    updateSelfRegistrationSetting(payload) {
        return http.post(`${API_ROOT}/provider/profile/selfregistration-setting`, payload).then(resp => {
            return resp
        })
    },
    async updateCentralizedSetting(payload) {
        return http.put(`${API_ROOT}/provider/profile/centralized-setting`, payload).then(resp => {
            return resp
        })
    },
    updateValidDomain(payload) {
        return http.put(`${API_ROOT}/provider/profile/valid-domain`, payload).then(resp => {
            return resp
        })
    },
    updateThreshold(payload) {
        return http.put(`${API_ROOT}/provider/profile/threshold`, payload).then(resp => {
            return resp
        })
    },
    async centralizedVerificationEmail(payload) {
        return http.post(`${API_ROOT}/provider/profile/centralized-verification`, payload).then(resp => {
            return resp
        })
    },
}

export default providerAppServices
