import roleServices from '../../../../services/role'
import recallServicesProv from '../../../../services/provider/recall'
import conf from '../../../../config'
import {getUserType } from '../../../../misc/auth'
import VerificationEmailModal from './VerificationEmailModal'
import { isNull } from 'lodash'
import ErrorModal from './../../../share/ErrorModal'

export const BASE_URL = `${conf.BASE_URL}`
export default {
    components: {
        VerificationEmailModal,
        ErrorModal
    },
    props: {
        item: { type: Object },
        initialItem: { type: Object },
        services: {type: Object}
    },
    data() {
        return {
            data: {
                SelfRegistrationId: 0,
                selfchecked: false,
                regnotichecked: false,
                registrationlink: BASE_URL,
                role_id: null,
                organization_id: '',

                isVerifed: false,
                emailstatus: null,

                centralizedChecked: false,
                allow_centralizedintake: false,
                centralizedEmailStatus: null,
                centralized_email: null,
                centralized_email_original: ''
            },
            disableVerfyStatus: false,
            disableBtn: false,
            domainError: '',
            roleMetas: [],
            tempLink: BASE_URL+'/'+'registration/',
            userType: getUserType(),
            urlcontent: '',
            oldsettingdata: {
                allow_customizedcontent: '',
                allow_paperresponse: '',
                allow_notisphere_access: '',
                allow_sales_rep_notifications: '',
                additionalProviderNotification: ''
            },
            verifyModalKey: 0,
            errorModalKey: 0,
            bounce_type: null,
            bounce_sub_type: null,
            description: null,
            isSSO: this.$store.state.organization.isSSO,
            idpName: this.$store.state.organization.idpName
        }
    },
    computed: {
        isDisabled() {
            // evaluate whatever you need to determine disabled here...
            return true
        }
    },
    watch: {
        item() {
            if(this.userType=='PROVIDER')
            {
                this.getRoleMeta(this.item.info.id)
            }
            this.data.organization_id=this.item.info.id
            this.data.registrationlink=BASE_URL+'/'+'registration/'+this.item.info.name
            this.urlcontent=this.item.info.name.replace(/ /g,'_')
            if(this.item.selfregistration!=null)
            {
                this.data.SelfRegistrationId=this.item.selfregistration.SelfRegistrationId
                this.data.selfchecked=this.item.selfregistration.selfchecked
                this.data.role_id=this.item.selfregistration.role_id
                this.data.regnotichecked=this.item.selfregistration.regnotichecked
                this.data.registrationlink=this.item.selfregistration.registrationlink
                var splitUrl = this.item.selfregistration.registrationlink.split('/registration/')
                this.urlcontent=splitUrl[1].replace(/ /g,'_')
                this.tempLink=splitUrl[0]+'/'+'registration/'
            }
            else
            {
                this.item.selfregistration = this.data
            }
            if(this.item.info!=null)
            {
                this.oldsettingdata.allow_customizedcontent=this.item.info.allow_customizedcontent
                this.oldsettingdata.allow_paperresponse=this.item.info.allow_paperresponse
                this.oldsettingdata.allow_notisphere_access=this.item.info.allow_notisphere_access
                this.oldsettingdata.allow_sales_rep_notifications=this.item.info.allow_sales_rep_notifications
                this.oldsettingdata.additionalProviderNotification=this.item.info.additionalProviderNotification
            }
            if (this.item.centralizedintake != null) {
                this.data.centralizedChecked = this.item.centralizedintake.is_setting_enabled
                this.data.centralized_email = this.item.centralizedintake.centralized_email
                this.data.centralizedEmailStatus = this.item.centralizedintake.centralized_email_status
                this.data.isVerifed = this.item.centralizedintake.is_emailverified
                this.data.bounce_type = this.item.centralizedintake.bounce_type
                this.data.bounce_sub_type = this.item.centralizedintake.bounce_sub_type
                this.data.description = this.item.centralizedintake.description
                this.data.extra_details = this.item.centralizedintake.extra_details
                this.data.centralized_email_original = this.item.centralizedintake.centralized_email
                if(this.item.centralizedintake.centralized_email == null || this.item.centralizedintake.centralized_email =='')
                {
                    this.disableVerfyStatus = true
                }
            }
        },
        data: {
            handler(newVal) {
                //debugger
                // if(this.compareObjects(newVal, this.initialItem)) {
                //     console.log('Settings')
                // }
                // this.$parent.hasUnsavedChanges = this.compareObjects(newVal, this.initialItem)
            },
            deep: true
        }
    },
    methods: {
        onPaste(e){
            var sd= e.clipboardData.getData('text')
            var newsd= sd.replace(/\W/g, '')
            if (newsd=='') {
                e.preventDefault()
            }
        },
        btKeyUp(e) {
            if (/^\W$/.test(e.key)) {
                e.preventDefault()
            }
        },
        async copy(s) {
            await navigator.clipboard.writeText(s)
            this._showToast('Registration url is copied successfully!')
        },
        async copyNew(s) {
            await navigator.clipboard.writeText(this.tempLink+s)
            this._showToast('Registration url is copied successfully!')
        },
        save(type) {
            // if(type=='AllowNotisphereAccess')
            // {
            //     this.item.info.allow_centralizedintake=this.oldsettingdata.allow_centralizedintake
            //     this.item.info.allow_customizedcontent=this.oldsettingdata.allow_customizedcontent
            //     this.item.info.allow_paperresponse=this.oldsettingdata.allow_paperresponse
            //     this.item.info.allow_sales_rep_notifications=this.oldsettingdata.allow_sales_rep_notifications
            //     this.item.info.additionalProviderNotification=this.oldsettingdata.additionalProviderNotification
            // }
            // if(type=='AllowCustomizedContent')
            // {
            //     this.item.info.allow_centralizedintake=this.oldsettingdata.allow_centralizedintake
            //     this.item.info.allow_notisphere_access=this.oldsettingdata.allow_notisphere_access
            //     this.item.info.allow_paperresponse=this.oldsettingdata.allow_paperresponse
            //     this.item.info.allow_sales_rep_notifications=this.oldsettingdata.allow_sales_rep_notifications
            //     this.item.info.additionalProviderNotification=this.oldsettingdata.additionalProviderNotification
            // }
            // if(type=='LogPaperResponses')
            // {
            //     this.item.info.allow_centralizedintake=this.oldsettingdata.allow_centralizedintake
            //     this.item.info.allow_customizedcontent=this.oldsettingdata.allow_customizedcontent
            //     this.item.info.allow_notisphere_access=this.oldsettingdata.allow_notisphere_access
            //     this.item.info.allow_sales_rep_notifications=this.oldsettingdata.allow_sales_rep_notifications
            //     this.item.info.additionalProviderNotification=this.oldsettingdata.additionalProviderNotification
            // }
            // if(type=='CentralizedIntakeEmail')
            // {
            //     this.item.info.allow_paperresponse=this.oldsettingdata.allow_paperresponse
            //     this.item.info.allow_customizedcontent=this.oldsettingdata.allow_customizedcontent
            //     this.item.info.allow_notisphere_access=this.oldsettingdata.allow_notisphere_access
            //     this.item.info.allow_sales_rep_notifications=this.oldsettingdata.allow_sales_rep_notifications
            //     this.item.info.additionalProviderNotification=this.oldsettingdata.additionalProviderNotification
            // }
            // if(type=='SalesRepNotifications')
            // {
            //     this.item.info.allow_centralizedintake=this.oldsettingdata.allow_centralizedintake
            //     this.item.info.allow_paperresponse=this.oldsettingdata.allow_paperresponse
            //     this.item.info.allow_customizedcontent=this.oldsettingdata.allow_customizedcontent
            //     this.item.info.allow_notisphere_access=this.oldsettingdata.allow_notisphere_access
            //     this.item.info.additionalProviderNotification=this.oldsettingdata.additionalProviderNotification
            // }
            // if(type=='AdditionalProviderNotifications')
            // {
            //     this.item.info.allow_centralizedintake=this.oldsettingdata.allow_centralizedintake
            //     this.item.info.allow_paperresponse=this.oldsettingdata.allow_paperresponse
            //     this.item.info.allow_customizedcontent=this.oldsettingdata.allow_customizedcontent
            //     this.item.info.allow_notisphere_access=this.oldsettingdata.allow_notisphere_access
            //     this.item.info.allow_sales_rep_notifications=this.oldsettingdata.allow_sales_rep_notifications
            // }
            // this.item.info.allow_centralizedintake=this.oldsettingdata.allow_centralizedintake
            // this.item.info.allow_customizedcontent=this.oldsettingdata.allow_customizedcontent
            // this.item.info.allow_paperresponse=this.oldsettingdata.allow_paperresponse
            // this.item.info.allow_sales_rep_notifications=this.oldsettingdata.allow_sales_rep_notifications
            // this.item.info.additionalProviderNotification=this.oldsettingdata.additionalProviderNotification
            // this.item.info.allow_notisphere_access=this.oldsettingdata.allow_notisphere_access
            this.services.updateSecuritySetting(this.item.info)
        },
        async saveCentralizedSetting() {
            var isValid = await this.$refs['observer'].validate()
            if(isValid &&(this.item.centralizedintake.centralized_email == '' ||this.item.centralizedintake.centralized_email != '' || this.item.centralizedintake.centralized_email == null))
            {
                if(this.item.centralizedintake.centralized_email == ''  || this.item.centralizedintake.centralized_email == null)
                {
                    this.item.centralizedintake.centralized_email = null
                }
                recallServicesProv.getDamain(this.item.centralizedintake.centralized_email, this.$store.state.orgId)
                    .then((resp) => {
                        if (resp.error) {
                            return false
                        } else {
                            this.data.centralized_email = this.item.centralizedintake.centralized_email
                            this.data.allow_centralizedintake = this.item.centralizedintake.is_setting_enabled
                            this.services.updateCentralizedSetting(this.data)
                            return true
                        }
                    })
            }
        },
        updateCentralizedSetting() {
            this.data.allow_centralizedintake = this.data.centralizedChecked
            this.services.updateCentralizedSetting(this.data)
        },
        checkDomainValidation() {
            this.domainError = ''
            recallServicesProv.getDamain(this.item.centralizedintake.centralized_email, this.$store.state.orgId)
                .then((resp) => {
                    if (resp.error) {
                        this.domainError = 'domainError'
                        return false
                    } else {
                        return true
                    }
                })
        },
        saveRegistrationSetting() {
            this.data.registrationlink=this.tempLink+this.urlcontent
            this.item.selfregistration.registrationlink=this.tempLink+this.urlcontent
            this.services.updateSelfRegistrationSetting(this.item.selfregistration)
        },
        check: function(e) {
            //after self registration unchecked
            if(e==false)
                this.data.selfchecked=false
        },
        checkNotification: function(e) {
            //after self registration unchecked
            if(e==false)
                this.data.regnotichecked=false
        },
        getRoleMeta(organization_id) {
            roleServices.getselfRegistrationMeta(organization_id).then(resp=>{
                if(!resp.error){
                    this.roleMetas = resp.data.d.filter(x => x.allowed_creation == true)
                    if(this.data.role_id==null)
                    {
                        this.data.role_id=resp.data.d[1].id
                    }
                    else{
                        var check=resp.data.d.filter(x => x.allowed_creation == true).filter(s=>s.id==this.data.role_id)
                        if(check.length==0)
                        {
                            this.data.role_id=resp.data.d[1].id
                        }
                    }
                }
            })
        },
        async openVerificationEmailModal(){
            if(this.disableBtn == false){
                var isValid = await this.$refs['observer'].validate()
                if(isValid && this.data.centralized_email != '')
                {
                    this.verifyModalKey += 1
                    this.$nextTick(() => {
                        this.$refs.verifyModal.toggle({id: 1})
                    })
                }
            }
        },
        showErrorModal() {
            this.data.errorModalKey += 1
            this.$nextTick(() => {
                this.$refs.errorModal.forceRerenderView({
                    bounce_type: this.item.centralizedintake.bounce_type,
                    bounce_sub_type: this.item.centralizedintake.bounce_sub_type,
                    description: this.item.centralizedintake.description,
                    extra_details: this.item.centralizedintake.extra_details

                })
            })
        },
        compareObjects(obj1, obj2) {
            return JSON.stringify(obj1) !== JSON.stringify(obj2)
        }
    }
}