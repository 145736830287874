import {http, param} from '../utils/http'
import conf from '../config'

export const API_ROOT = `${conf.API_URL}`

const roleServices = {
    getMeta(organization_id,isNonVerifiedChecked) {
        return http.get(`${API_ROOT}/roles/meta?organization_id=${organization_id ? organization_id : ''}&isNonVerifiedChecked=${isNonVerifiedChecked}`).then(resp => {
            return resp
        })
    },
    getselfRegistrationMeta(organization_id) {
        return http.get(`${API_ROOT}/roles/selfregistrationmeta?organization_id=${organization_id ? organization_id : ''}`).then(resp => {
            return resp
        })
    },
    findRole(payload) {
        var arr = [
            { query: 'pageNumber', val: payload.pageNumber },
            { query: 'pageSize', val: payload.pageSize },
            { query: 'sortField', val: payload.sortField },
            { query: 'sortDirection', val: payload.sortDirection },
            { query: 'search', val: payload.search ? payload.search.trim() : '' },
            { query: 'organizationId', val: payload.organizationId },
        ]
        return http.get(`${API_ROOT}/roles${param(arr)}`).then(resp => {
            return resp
        })
    },
    getRole(id) {
        return http.get(`${API_ROOT}/roles/${id}`).then(resp => {
            return resp
        })
    },
    getPermissionMeta(organization_type) {
        return http.get(`${API_ROOT}/permissions/meta?organization_type=${organization_type}`).then(resp => {
            return resp
        })
    },
    createRole(payload) {
        return http.post(`${API_ROOT}/roles`, payload).then(resp => {
            return resp
        })
    },
    updateRole(payload) {
        return http.put(`${API_ROOT}/roles/${payload.id}`, payload).then(resp => {
            return resp
        })
    },
    deleteRole(id) {
        return http.delete(`${API_ROOT}/roles/${id}`).then(resp => {
            return resp
        })
    }
}

export default roleServices
